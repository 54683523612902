.Payment {
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &Price {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    color: var(--color-gray-800);
  }

  &Text {
    color: var(--color-gray-400);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    text-transform: capitalize;
    // padding-bottom: 1rem;
  }
}

.Large {
  font-size: 1.6rem;
}
